import { Link } from "gatsby"
import React, { Component } from "react"

class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false }
    this.handleClick = this.handleClick.bind(this)
  }
  handleClick() {
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }))
  }

  render() {
    return (
      <nav className="flex items-center justify-between flex-wrap bg-blue-500 p-3 shadow-md">
        <div className="flex items-center flex-no-shrink text-white mr-6">
          <span className="font-semibold text-xl tracking-tight">
            faulty.cloud
          </span>
        </div>
        <div className="block lg:hidden">
          <button
            className="flex items-center px-3 py-2 border rounded text-blue-lighter border-blue-light hover:text-white hover:border-white"
            onClick={this.handleClick}
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        <div
          className={
            "w-full flex-grow lg:flex lg:items-center lg:w-auto " +
            (this.state.showMenu ? "block" : "hidden")
          }
        >
          <div className="text-sm lg:flex-grow">
            <Link
              to="/"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-100 hover:text-white mr-4"
            >
              Home
            </Link>
            <Link
              to="/projects/"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-100 hover:text-white mr-4"
            >
              Projects
            </Link>
            <Link
              to="/blog/"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-100 hover:text-white mr-4"
            >
              Blog
            </Link>
          </div>
        </div>
      </nav>
    )
  }
}

export default Menu
